const TermsAndConditions = () => {
    return <div>
        <div className="policy-header main-header-policy">Terms and Conditions</div>
        <div>
            <span className="policy-body">Effective Date: August 14, 2024</span>
            <div className="individual-policies">
                <span className="policy-header">1. Introduction</span>
                <span className="policy-body">
                    Welcome to The Divash. By accessing our website thedivash.com and placing an order, you agree to comply with and be bound by these Terms and Conditions. Please read these terms carefully before using our services.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">2. Placing an Order</span>
                <span className="policy-body">
                    Orders are placed via our website thedivash.com. Once you place an order, you will receive a confirmation email acknowledging the receipt and acceptance of your order by The Divash.
                    The contract for the delivery of the meal is between you and The Divash and is formed only when you receive this confirmation.
                    Please ensure the correct address and contact details are provided. Failure to respond to delivery calls or open the door within 10 minutes of the delivery driver's arrival may result in the cancellation of the delivery, and you will be charged in full for the meal.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">3. Price and Payment</span>
                <span className="policy-body">
                    All prices listed on the website are subject to change without notice. The prices listed at the time of placing the order are final, barring any obvious pricing errors.
                    Payment must be made online via debit/credit card or other payment methods offered at checkout. All payments include applicable taxes.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">4. Delivery</span>
                <span className="policy-body">
                    Delivery times are estimates and may vary based on factors like traffic and weather. We aim to deliver your order promptly but cannot guarantee exact times.
                    In case of delays, we will notify you, and if necessary, you have the option to cancel your order, subject to our cancellation policy.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">5. Cancellation</span>
                <span className="policy-body">
                    Orders can be canceled within a reasonable time before the food preparation begins. If the food preparation has already started, the order cannot be canceled, and no refund will be issued.
                    If you wish to cancel, please contact our customer service team immediately. In the event of an issue or a problem with your order, please reach out to us to resolve the matter.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">6. Allergies and Dietary Preferences</span>
                <span className="policy-body">
                    The Divash does not guarantee that any food item sold is free from allergens. If you have any allergies, please inform us by leaving a note with your order. However, we are not responsible for any adverse reactions.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">7. Coupons and Promotions</span>
                <span className="policy-body">
                    Promotional codes are for one-time use only and cannot be redeemed for cash or credit. They must be used during the checkout process and cannot be applied after the order is place.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">8. Governing Law</span>
                <span className="policy-body">
                    These terms are governed by the laws of India. Any disputes will be subject to the exclusive jurisdiction of the courts in Bangalore, Karnataka.
                </span>
            </div>
        </div>

    </div>
}

export default TermsAndConditions