const DeliveryPolicy = () => {
    return <div>
        <div className="policy-header main-header-policy">Delivery Policy</div>
        <div>
            <span className="policy-body">Effective Date: August 14, 2024</span>
            <div className="individual-policies">
                <span className="policy-header">1. Delivery Areas</span>
                <span className="policy-body">
                The Divash offers food delivery services within Bangalore, Karnataka. We strive to ensure timely and safe delivery to the specified address provided by the customer. Delivery areas are limited to certain locations within Bangalore, and orders outside the designated areas may not be processed.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">2. Delivery Times</span>
                <span className="policy-body">
                We operate daily from 9:00 AM to 11:00 PM, including weekends and public holidays. Our average delivery time is between 30 to 60 minutes, depending on the distance and traffic conditions. Please note that during peak hours, holidays, or inclement weather, delivery times may be longer.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">3. Order Process</span>
                <span className="policy-body">
                Once an order is placed and confirmed via our website, you will receive a confirmation email or SMS with the estimated delivery time. Please ensure that the address and contact details provided are accurate to facilitate a smooth delivery process.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">4. Delivery Charges</span>
                <span className="policy-body">
                Delivery charges may vary based on your location and order size. We clearly indicate delivery charges during the checkout process before confirming the order.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">5. Customer Responsibility</span>
                <span className="policy-body">
                Customers are requested to be available at the delivery address at the scheduled delivery time. In cases where the delivery partner cannot reach the customer despite reasonable efforts (e.g., unable to contact the customer or enter the premises), the order may be canceled, and no refund will be provided.
                </span>
            </div>
        </div>

    </div>
}

export default DeliveryPolicy