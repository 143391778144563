import React, { useEffect } from 'react';
import axios from '../utils/webcall';
import { useSearchParams } from "react-router-dom";
import { API_ENDPOINT } from '../App';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";



const CheckPaymentStatus = () => {
    const [searchParams, _] = useSearchParams();
    const navigate = useNavigate();


    useEffect(() => {
        let transactionID = searchParams.get('id');
        console.log(transactionID);
        axios.get(`${API_ENDPOINT}/order/status?transactionID=${transactionID}`).then(res => {
            let data = res.data;
            let order_id = data.order_id
            console.log(data);
            if (data['state'] === "COMPLETED") {
                // alert("Transaction successful");
                navigate(`/orderPlaced?order_id=${order_id}`)
            }
            else {
                // alert("transaction failed");
                navigate(`/cart?payment=failed&transactionID=${transactionID}`)
            }
            console.log(data);
        }).catch(err => {
            console.log("Error")
            console.log(err)
            navigate(`/cart?payment=failed&transactionID=${transactionID}`)
        })
    }, [])

    return (<div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
    }}>
        <SyncLoader />

    </div>
    );
}

export default CheckPaymentStatus;