const RefundPolicy = () => {
    return <div>
        <div className="policy-header main-header-policy">Refund Policy</div>
        <div>
            <span className="policy-body">Effective Date: August 14, 2024</span>
            <div className="individual-policies">
                <span className="policy-header">1. Order Cancellation</span>
                <span className="policy-body">
                    Once an order is confirmed, it cannot be canceled. In the rare event of an issue with the delivery or preparation, you may contact our customer service, and we will do our best to resolve the issue.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">2. Food Order Errors</span>
                <span className="policy-body">
                    If you receive an incorrect order, please contact us immediately upon receipt of your food. We will correct the error either by sending the correct items or issuing a refund in the form of store credit.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">3. Incomplete Orders</span>
                <span className="policy-body">
                    If any items from your order are missing, you may cancel the missing items before they are prepared, and we will issue a refund for the missing items either to your original payment method or as store credit, within 30 business days.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">4. Unsatisfactory Food</span>
                <span className="policy-body">
                    We take pride in preparing fresh and high-quality meals. However, if you are dissatisfied with the food received due to a fault on our part, please contact us immediately, and we will review the issue. Refunds or replacements may be offered in cases of poor quality or incorrect preparation within 30 business days. Refunds are not typically provided for orders where the food was simply disliked.
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">5. Non-Refundable Cases</span>
                <span className="policy-body">
                    <ul>
                        <li>We do not offer refunds if you change your mind after the order has been prepared.</li>
                        <li>No refunds will be provided for non-food items or beverages.</li>
                        <li>If the food has been consumed or tampered with, we cannot offer any refunds or credits.</li>
                    </ul>
                </span>
            </div>
            <div className="individual-policies">
                <span className="policy-header">6. Delivery Partner Cancellations</span>
                <span className="policy-body">
                    If a delivery partner is unable to deliver your food due to being unable to locate you, and after reasonable attempts to contact you, the order will be considered complete, and no refunds will be issued.
                </span>
            </div>
        </div>

    </div>
}

export default RefundPolicy