import React from "react";
import "../styles/footer.css"
import { Link } from "react-router-dom";

const Footer = () => {
    return <div id="footer">
        <div id="footer-branding">
            <div className="stylishHeading" style={{fontWeight: "bold", fontSize: "1.5rem"}}>DivAsh Enterprises LLP</div>
            <div id="tagline">Delicious food, delivered to you.</div>
        </div>
        <div id="footer-info">
            <div><Link to="/contact-us">Contact Us</Link></div>
            <div><Link to="/policy">Our Policies</Link></div>
            {/* <div>About Us</div> */}
        </div>
        <div id="copyright">© 2024 DivAsh Enterprises LLP. All rights reserved.</div>
    </div>
}

export default Footer