import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../App.css";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        navigate('/order');
    }, [navigate]) // Include navigate in the dependency array

    return (
        <div id="PageContainer">
            <Header />
            <div id="BodyContent">
                <h1>Coming Soon</h1>
                <p>Our website is under construction. Stay tuned for updates!</p>
            </div>
            <Footer />
        </div>
    );
};

export default Home;
