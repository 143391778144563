// utils/paymentGateway.js
import { API_ENDPOINT } from "../App";
import Cookies from 'react-cookies';

export const payNow = async (userData, orderData, transactionData) => {
    const response = await fetch(`${API_ENDPOINT}/order/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user: userData,
            order: orderData,
            transaction: transactionData
        })
    });

    const data = await response.json();

    if (response.ok) {
        Cookies.save('last_transaction_id', data.transaction_id, { path: '/' });
        window.location.href = data.payment_url; // Redirect to the payment URL
    } else {
        console.info(response)
        alert("Error processing payment: " + data.error);
    }
};
