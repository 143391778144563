const PrivacyPolicy = () => {
    return <div>
    <div className="policy-header main-header-policy">Privacy Policy</div>
    <div>
        <span className="policy-body">Effective Date: August 14, 2024</span>
        <div className="individual-policies">
            <span className="policy-header">1. Introduction</span>
            <span className="policy-body">
                At The Divash, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website thedivash.com.
            </span>
        </div>
        <div className="individual-policies">
            <span className="policy-header">2. Information we collect</span>
            <span className="policy-body">
                <ul>
                    <li>Personal Information: We collect information such as your name, email address, phone number, and delivery address when you place an order.</li>
                    <li>Payment Information: Payment details are processed through secure payment gateways and are not stored on our servers.</li>
                    <li>Usage Data: We may collect information about your usage of our website, including IP addresses, browser types, and access times.</li>
                </ul>
            </span>
        </div>
        <div className="individual-policies">
            <span className="policy-header">3. How we use you Information</span>
            <span className="policy-body">
                <ul>
                    <li>Order Processing: To fulfill your orders and provide customer support.</li>
                    <li>Marketing: With your consent, we may send promotional offers and updates about our services. You can opt out of marketing communications at any time.</li>
                    <li>Improvement: To analyze usage and improve our services and user experience.</li>
                </ul>
            </span>
        </div>
        <div className="individual-policies">
            <span className="policy-header">4. Cookies</span>
            <span className="policy-body">
                We use cookies to enhance your browsing experience. Cookies help us remember your preferences and provide personalized content. You can disable cookies in your browser settings, but this may limit the functionality of the website.
            </span>
        </div>
        <div className="individual-policies">
            <span className="policy-header">5. Sharing of Information</span>
            <span className="policy-body">
                <ul>
                    <li>Third Parties: We do not sell your personal data to third parties. However, we may share your data with service providers involved in processing payments or delivering orders.</li>
                    <li>Legal Requirements: We may disclose your information if required by law or to protect the rights, property, or safety of The Divash, our customers, or others.</li>
                </ul>
            </span>
        </div>
        <div className="individual-policies">
            <span className="policy-header">6. Data Security</span>
            <span className="policy-body">
                We implement appropriate security measures to protect your data from unauthorized access, alteration, or disclosure. However, no internet-based service is completely secure, and we cannot guarantee absolute security.
            </span>
        </div>
        <div className="individual-policies">
            <span className="policy-header">7. Changes to this Policy</span>
            <span className="policy-body">
                We may update this Privacy Policy from time to time. Any changes will be posted on our website with the effective date of the updated policy.
            </span>
        </div>
        <div className="individual-policies">
            <span className="policy-header">8. Contact Us</span>
            <span className="policy-body">
                If you have any questions or concerns about our Privacy Policy, please contact us at thedivash@gmail.com.
            </span>
        </div>
    </div>

</div>
}

export default PrivacyPolicy