export const filterItems = (items, query, categoryMapping) => {
    // Trim the query to remove leading and trailing spaces
    const trimmedQuery = query.trim().toLowerCase();

    return items.filter(item => {
        // Convert item fields to lowercase for case-insensitive comparison
        const itemName = item.item_name.toLowerCase();
        const itemDescription = item.description.toLowerCase();
        const categoryTitle = (categoryMapping[item.item_category] || "").toLowerCase();

        // Check if any of the item fields include the trimmed query
        return (
            itemName.includes(trimmedQuery) ||
            itemDescription.includes(trimmedQuery) ||
            categoryTitle.includes(trimmedQuery)
        );
    });
};