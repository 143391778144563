import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../App.css";
import "../styles/card.css";
import { Link } from "react-router-dom";

const NotFound = () => {
    return <div id="PageContainer">
        <Header />
        <div id="BodyContent" className="notFound">
            <div id="blank404">&nbsp;</div>
            <Link to="/order"><div className="additem orderbutton">Order</div></Link>
        </div>
        <Footer />
    </div>
};

export default NotFound