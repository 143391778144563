import React, { useEffect, useState } from "react";
import "../styles/card.css";
import VegImg from "../images/Veg.png";
import NonVegImg from "../images/NonVeg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceHolderImage from "../images/divash_placeholder.png";
import Cookies from 'react-cookies';

const Card = ({ name, price, desc, nonveg, imgsrc, serves, elemid }) => {
    const [cartItems, setCartItems] = useState([]);

    // Update cart items from cookies and initialize state
    const loadCartItems = () => {
        let cart = Cookies.load('cart') || [];
        setCartItems(cart);
    };

    // Update cart number display in header
    const updateCartNumber = () => {
        let cart = Cookies.load('cart') || [];
        let totalItems = cart.reduce((total, item) => total + item.qty, 0);
        let cartNumberElement = document.getElementById('cart-number');
        if (cartNumberElement) {
            if (totalItems === 0) {
                cartNumberElement.innerHTML = "";
                cartNumberElement.style.display = "none";
            } else if (totalItems > 9) {
                cartNumberElement.innerHTML = "9+";
                cartNumberElement.style.display = "flex";
            } else {
                cartNumberElement.innerHTML = totalItems.toString();
                cartNumberElement.style.display = "flex";
            }
        }
    };

    // Add item to cart
    const addToCart = (item) => {
        let cart = Cookies.load('cart') || [];
        let itemIndex = cart.findIndex(cartItem => cartItem.id === item);

        if (itemIndex > -1) {
            cart[itemIndex].qty += 1;
        } else {
            cart.push({ id: item, qty: 1 });
        }

        Cookies.save('cart', cart, { path: '/' });
        loadCartItems(); // Update state with new cart data
        updateCartNumber(); // Update header cart number
    };

    // Remove item from cart
    const removeFromCart = (item) => {
        let cart = Cookies.load('cart') || [];
        let itemIndex = cart.findIndex(cartItem => cartItem.id === item);

        if (itemIndex > -1) {
            if (cart[itemIndex].qty > 1) {
                cart[itemIndex].qty -= 1;
            } else {
                cart.splice(itemIndex, 1);
            }
        }

        Cookies.save('cart', cart, { path: '/' });
        loadCartItems(); // Update state with new cart data
        updateCartNumber(); // Update header cart number
    };

    // Initialize cart items on mount and update every second
    useEffect(() => {
        loadCartItems(); // Load cart items from cookies
        updateCartNumber(); // Update cart number on component mount

        const intervalId = setInterval(() => {
            loadCartItems(); // Refresh cart items periodically
            updateCartNumber(); // Refresh cart number periodically
        }, 1000);

        return () => clearInterval(intervalId); // Clean up on component unmount
    }, []);

    // Check if the item is in the cart
    const itemInCart = cartItems.find(cartItem => cartItem.id === elemid);

    return (
        <div className="card_body">
            <div className="card_img">
                <LazyLoadImage placeholderSrc={PlaceHolderImage} src={`${imgsrc}`} alt="Food" />
            </div>
            <div className="card_info">
                <div className="nonvegimg">
                    {nonveg ? <LazyLoadImage alt="nonveg" src={NonVegImg} /> : <LazyLoadImage alt="veg" src={VegImg} />}
                </div>
                <div className="item_name stylishHeading">
                    {name}
                </div>
                <div className="item_price_and_serves">
                    <div className="item_price">
                        ₹{price}
                    </div>
                    <div className="item_serves">
                        Serves {serves}
                    </div>
                </div>
                <div className="item_info">
                    {desc}
                </div>
                <button className="additem">
                    {
                        itemInCart ?
                            <div id="addMoreText">
                                <div id="minusCart" onClick={() => removeFromCart(elemid)}>-</div>
                                <div id="numberCart">{itemInCart.qty}</div>
                                <div id="plusCart" onClick={() => addToCart(elemid)}>+</div>
                            </div>
                            :
                            <div id="addText" onClick={() => addToCart(elemid)}>ADD</div>
                    }
                </button>
            </div>
        </div>
    );
};

export default Card;
