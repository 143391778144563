import React from "react";
import EmptyCartImg from "../images/EmptyCart.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../styles/cart.css"
import { Link } from "react-router-dom";
import PlaceHolderImage from "../images/divash_placeholder.png";

const EmptyCart = () => {
    return <div id="emptyCart">
        <LazyLoadImage placeholderSrc={PlaceHolderImage} src={EmptyCartImg} alt="Empty Cart" />
        <div style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            padding: "25px 0 10px 0"
        }}>Your cart is empty</div>
        <div style={{
            fontSize: "1rem",
            textAlign: "center",
            paddingBottom: "5px"
        }}>Good food is always cooking!<br/> Go ahead, order some yummy items from the menu.</div>
        <Link to="/order"><div className="additem orderbutton">Add Items</div></Link>
    </div>
}

export default EmptyCart