import React, { useEffect, useState } from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import { useSearchParams } from "react-router-dom";
import "../styles/order_status.css";
import axios from "../utils/webcall";
import { API_ENDPOINT } from '../App';
import SyncLoader from "react-spinners/SyncLoader";
import Cookies from 'react-cookies';

const orderStatusMessage = (order_status) => {
    if (order_status === 'AC') {
        return 'Order has been accepted. Preparation will begin soon';
    }
    if (order_status === 'PR') {
        return 'Your order is being prepared';
    }
    if (order_status === 'PD') {
        return 'Order is ready. Waiting for delivery agent';
    }
    if (order_status === 'AC') {
        return 'Yummy food on the way. Order is out for delivery';
    }
    if (order_status === 'AC') {
        return 'Order delivered. Bon Appétit';
    }
}

const OrderSuccessPage = () => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [orderID, setOrderID] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [needHelpMessage, setNeedHelpMessage] = useState('')

    useEffect(() => {
        let temp = searchParams.get('order_id');
        setOrderID(temp);
        setNeedHelpMessage(`Need help with order ID: ${temp}`)
        let getOrderInfo = async (params) => {
            let payload = {
                'order_id': temp
            }
            let res = await axios.post(`${API_ENDPOINT}/order/info`, payload);
            let data = res.data;
            setOrderDetails(data);
            Cookies.remove('cart');
            Cookies.save('last_order_data', data, { path: "/" });
        }
        getOrderInfo();
    }, [])
    return (
        <>
            {orderDetails == null ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw'
                }}>
                    <SyncLoader />

                </div>
            ) : (
                <div id='PageContainer'>
                    <Header />
                    <div id="orderCompletionPage">
                        <div id='orderStatusHeaderTop'>Order placed</div>
                        <div id='orderStatusMessage'>{orderStatusMessage(orderDetails.order_status)}</div>
                        <div id='orderPageSubMessage'>Get excited. You are going to get your order soon.</div>
                        <div id='orderPageDeliveringTo'>Delivering to</div>
                        <div id='orderDeliveryAddress'>{orderDetails.address}</div>
                        <div id='orderID1'>Your Order ID is</div>
                        <div id='orderID2'>#{orderID}</div>
                        <div id="itemDetailsContainer">
                            <div id='orderItems'>Item details</div>
                            {Object.keys(orderDetails.items).map((item, index) => {
                                return (
                                    <div class='itemDetailsContainer'>
                                        <div class='itemName'>{orderDetails.items[item].name}</div>
                                        <div class='itemQuantity'>{orderDetails.items[item].quantity} Qty</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div id='orderNeedHelp'>
                            <a
                                href={`https://wa.me/+917259519543?text=${encodeURIComponent(needHelpMessage)}`}
                                target="_blank"
                            >
                                Need help?
                            </a>
                        </div>
                    </div>
                    <Footer />
                </div>)
            }
        </>
    );
}

export default OrderSuccessPage;
