import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../styles/header.css";
import DivashHeader from "../images/header_logo.png";
import { IoSearchCircle } from "react-icons/io5";
import { FaBagShopping } from "react-icons/fa6";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Cookies from 'react-cookies';





const Header = ({ onSearchToggle }) => {
    const [totalItems, setTotalItems] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();

    const headerStylePageDependent = () => {
        if (location.pathname === '/order') {
            return {}
        }
        return {
            display: 'flex',
            justifyContent: "center",
            alignContent: 'center'
        }
    }

    const updateCartNumber = () => {
        let cart = Cookies.load('cart') || [];
        let totalItems = cart.reduce((total, item) => total + item.qty, 0);
        setTotalItems(totalItems);
    };

    const showSearchAndCartIcon = () => {
        return location.pathname === '/order';
    }

    useEffect(() => {
        updateCartNumber(); // Update cart number on component mount

        const intervalId = setInterval(() => {
            updateCartNumber(); // Update cart number periodically
        }, 1000);

        return () => clearInterval(intervalId); // Clean up on component unmount
    }, []);

    const handleSearchToggle = () => {
        if (location.pathname !== '/order') {
            navigate('/order', { state: { showSearch: true } });
        } else {
            onSearchToggle();
        }
    }

    return (
        <div className="header">
            <div id="header" style={headerStylePageDependent()}>
                {showSearchAndCartIcon() ? (<div id="header-search" role="button" tabIndex={0} onClick={handleSearchToggle}>
                    <IoSearchCircle style={{ color: "white", fontSize: "2.7rem", marginTop: "5px" }} />
                </div>) : <></>}
                <Link tabIndex={-1} to="/">
                    <div id="header-logo">
                        <LazyLoadImage src={DivashHeader} alt="Divash Logo" />
                    </div>
                </Link>
                {showSearchAndCartIcon() ? (<Link tabIndex={-1} to="/cart">
                    <div id="header-cart" role="button" tabIndex={0}>
                        <div id="cart-number" style={{ display: totalItems ? 'flex' : 'none' }}>
                            {totalItems > 9 ? "9+" : totalItems}
                        </div>
                        <div id="cart-logo" aria-hidden="true">
                            <FaBagShopping aria-hidden="true" style={{ color: "#1d3251", fontSize: "1rem" }} />
                        </div>
                    </div>
                </Link>) : <></>}
            </div>
        </div>
    );
};

export default Header;
