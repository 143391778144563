import React, { useEffect, useState, useCallback } from "react";
import Cookies from 'react-cookies';
import "../styles/card.css";
import VegImg from "../images/Veg.png";
import NonVegImg from "../images/NonVeg.png";
import axios from "../utils/webcall";
import { API_ENDPOINT } from "../App";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingText from "./loadingText";

import WhatsAppLogo from "../images/WhatsApp.png";
import SwiggyLogo from "../images/swiggy.png";
import ZomatoLogo from "../images/zomato.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const updateCartNumber = () => {
  let cart = Cookies.load('cart') || [];
  let totalItems = cart.reduce((total, item) => total + item.qty, 0);
  let cartNumberElement = document.getElementById('cart-number');

  if (cartNumberElement) {
    if (totalItems === 0) {
      cartNumberElement.innerHTML = "";
      cartNumberElement.style.display = "none";
    } else if (totalItems > 9) {
      cartNumberElement.innerHTML = "9+";
      cartNumberElement.style.display = "flex";
    } else {
      cartNumberElement.innerHTML = totalItems.toString();
      cartNumberElement.style.display = "flex";
    }
  }
};

const ensureCartCookie = () => {
  let cart = Cookies.load('cart');
  if (!cart) {
    Cookies.save('cart', [], { path: '/' });
  }
};

const ListComponent = ({ items }) => {
  const [cartItems, setCartItems] = useState([]);
  const [bill, setBill] = useState([0, 0]);
  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [refreshItems, setRefreshItems] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [fromFailedTransaction, setFromFailedTransaction] = useState(false);
  const [failedTransactionID, setFailedTransactionID] = useState('');
  const [isStoreOpen, setIsStoreOpen] = useState(false);
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if arrived on this page from a failed redirect
    let hasFailed = searchParams.get('payment')
    if (hasFailed !== null && hasFailed.toLowerCase() === 'failed') {
      let tid = searchParams.get('transactionID')
      setFailedTransactionID(tid);
      setFromFailedTransaction(true);
    }
  }, []);

  useEffect(() => {
    axios.get(`${API_ENDPOINT}/store/status`).then((res) => {
      let data = res.data;
      if (data.status === 'open') {
        setIsStoreOpen(true);
      }
      else {
        setIsStoreOpen(false);
      }
    })
  }, []);

  useEffect(() => {
    ensureCartCookie();
    updateCartNumber(); // Update cart number on component mount
  }, [items]);

  useEffect(() => {
    const fetchData = async () => {

      const tempArray = [];
      for (const item of items) {
        try {
          const response = await axios.get(`${API_ENDPOINT}/inv/${item.id}`);
          tempArray.push(response.data);
        } catch (error) {
          console.error(error);
        }
      }
      setCartItems(tempArray);
    };

    if (items.length > 0) {
      fetchData();
    }
  }, [refreshItems, items])

  useEffect(() => {
    let whatsappMessage = "Hello, I would like to order the following:";
    cartItems.forEach(i => {
      let cartItem = items.find(cartItem => cartItem.id === i.id);
      if (cartItem) {
        let details = cartItems.find(details => details.id === i.id);
        whatsappMessage += "\n" + (details.item_name || 'Item') + " * " + (cartItem.qty || 0);
      }
    });
    setWhatsappUrl(`https://wa.me/+917259519543?text=${encodeURIComponent(whatsappMessage)}`);
  }, [cartItems, items]);


  const getBill = useCallback(() => {
    let initBill = 0;
    let gstBill = 0;

    cartItems.forEach(i => {
      const cartItem = items.find(cartItem => cartItem.id === i.id);
      if (cartItem) {
        initBill += cartItem.qty * i.price;
      }
    });
    initBill = Math.round(initBill * 100) / 100;
    gstBill = Math.round(initBill * 18) / 100; // TODO get GST rate from backend
    gstBill = Math.round(initBill * 0) / 100; // set GST to 0
    setBill([initBill, gstBill]);
  }, [cartItems, items]);

  useEffect(() => {
    getBill();
  }, [cartItems, getBill]);

  const addToCart = (item) => {
    let cart = Cookies.load('cart') || [];

    let itemIndex = cart.findIndex(cartItem => cartItem.id === item);
    if (itemIndex > -1) {
      cart[itemIndex].qty += 1;
    } else {
      cart.push({ id: item, qty: 1 });
    }

    Cookies.save('cart', cart, { path: '/' });
    updateCartNumber();
    fetchCartItems(); // Fetch updated items to ensure UI reflects changes
    setRefreshItems(!refreshItems);
  };

  const removeFromCart = (item) => {
    let cart = Cookies.load('cart') || [];

    let itemIndex = cart.findIndex(cartItem => cartItem.id === item);

    if (itemIndex > -1) {
      if (cart[itemIndex].qty > 1) {
        cart[itemIndex].qty -= 1;
      } else {
        cart.splice(itemIndex, 1);
      }
    }

    Cookies.save('cart', cart, { path: '/' });
    updateCartNumber(); // Update cart number after removing from cart
    fetchCartItems(); // Fetch updated items to ensure UI reflects changes
    setRefreshItems(!refreshItems);
  };

  const fetchCartItems = async () => {
    const tempArray = [];
    for (const item of items) {
      try {
        const response = await axios.get(`${API_ENDPOINT}/inv/${item.id}`);
        tempArray.push(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    setCartItems(tempArray);
  };

  return (
    <div id="cartDetails">
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showSnackBar}
          autoHideDuration={20000}
          onClose={(e) => {
            setShowSnackBar(false);
          }}
          key='snackBar'
        >
          <Alert
            onClose={(e) => {
              setShowSnackBar(false);
            }}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </>
      {fromFailedTransaction ?
        (<Alert severity="error">
          Your transaction failed. Try again?<br />
          <a
            href={`https://wa.me/+917259519543?text=${encodeURIComponent('Need help with Transaction ID:' + failedTransactionID)}`}
            target='_blank'
          >
            Need help?
          </a><br />
          Transaction ID: <i>{failedTransactionID}</i>
        </Alert>) : <></>}
      <div id="cartProducts">
        {cartItems.length === 0 ? (
          <div className="loadingContainer">
            <LoadingText />
          </div>
        ) : (
          cartItems.map((item, index) => {
            const cartItem = items.find(cartItem => cartItem.id === item.id);
            const itemName = item.item_name || 'Loading...';
            const itemPrice = cartItem ? `₹${(cartItem.qty || 0) * (item.price || 0)}` : 'Loading...';
            const itemQty = cartItem ? cartItem.qty : 0;

            return (
              <div id="cartItemDetails" key={index}>
                <div className="cartItemImg">
                  {item.nonveg ? (
                    <LazyLoadImage src={NonVegImg} alt="Non-Veg Logo" />
                  ) : (
                    <LazyLoadImage src={VegImg} alt="Veg Logo" />
                  )}
                </div>
                <div className="cartItemName">{itemName}</div>
                <button className="additem cartadditem">
                  <div id="addMoreText">
                    <div id="minusCart" onClick={() => removeFromCart(item.id)}>-</div>
                    <div id="numberCart">{itemQty}</div>
                    <div id="plusCart" onClick={() => addToCart(item.id)}>+</div>
                  </div>
                </button>
                <div id="cartItemPrice" className="priceText">
                  {itemPrice}
                </div>
              </div>
            );
          })
        )}
      </div>
      <div id="couponCodeContainer">
        <div id="couponQuestion" >
          Have a Coupon Code ?
        </div>
        <div>
          <div id="couponInputContainer">
            <label className='form-labels' htmlFor="name">Code <span className='required-text'>*</span></label>
            <input
              type="text"
              id="coupon_code"
              required
            />
          </div>
          <div id="couponCodeSubmitButtonContainer">
            <button type="button" id="placeOrder" onClick={(e) => {
              setSnackBarMessage('This is a not a valid Coupon Code');
              setShowSnackBar(true);
            }}>Apply</button>
          </div>
        </div>
      </div>
      <div id="billDetails">
        {cartItems.length === 0 ? (
          null
        ) : (
          <>
            <div id="billHeader">Bill Details</div>
            <div id="billData">
              <div><span>Item Total</span><span className="priceText">₹{bill[0]}</span></div>
              <div><span>Delivery Fee</span><span className="priceText">₹30</span></div>
              <div><span>GST</span><span className="priceText">₹{bill[1]}</span></div>
            </div>
          </>
        )}
        {cartItems.length === 0 ? (
          null
        ) : (
          <>
            <div id="totalBill"><span className="totalPay">TO PAY</span><span>₹{Math.round((bill[0] + bill[1] + (bill[0] !== 0 ? 30 : 0)) * 100) / 100}</span></div>
            <div id="paymentOptions">
              <div className="mobile-options">
                <button className="pay-online" onClick={(e) => {
                  if (isStoreOpen) {
                    navigate("/checkout");
                  }
                  else {
                    setSnackBarMessage('Currently not Accepting Orders')
                    setShowSnackBar(true);
                  }

                }} >
                  <span>Proceed to Checkout Online</span>
                </button>
                <button className="whatsapp-order" onClick={() => {
                  window.open(whatsappUrl, "_blank");
                }}>
                  <span className="payImages"><LazyLoadImage src={WhatsAppLogo} /></span><span>Order via Whatsapp</span>
                </button>
                {/* <button className="swiggy-order"><span className="payImages"><LazyLoadImage src={SwiggyLogo} /></span><span>Order via Swiggy</span></button>
                <button className="zomato-order"><span className="payImages"><LazyLoadImage src={ZomatoLogo} /></span><span>Order via Zomato</span></button> */}

              </div>
              <div className="desktop-options">
                <button className="pay-online" onClick={(e) => {
                  if (isStoreOpen) {
                    navigate("/checkout");
                  }
                  else {
                    setSnackBarMessage('Currently not Accepting Orders')
                    setShowSnackBar(true);
                  }

                }} >
                  {/* <span className="payImages"><LazyLoadImage src={UpiLogo} /></span><span>Proceed to Checkout</span> */}
                  <span>Proceed to Checkout Online</span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ListComponent;
