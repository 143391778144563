import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../App.css"

const ContactUs = () => {
    return <div id="PageContainer">
        <Header />
        <div id="BodyContent">
            <h1>Contact Us</h1>
            <p>Here's how you can reach us:</p>
            <ul>
            <li>Email: thedivash@gmail.com</li>
            <li>Phone: (+91) 7259 519 543</li>
            <li>Address: Bangalore</li>
            </ul>
        </div>
        <Footer />
    </div>
};

export default ContactUs