import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";

import TermsAndConditions from "../components/TermsAndConditions";
import PrivacyPolicy from "../components/PrivacyPolicy";
import RefundPolicy from "../components/RefundPolicy";
import DeliveryPolicy from "../components/DeliveryPolicy";

const Policy = () => {
    const location = useLocation();
    const topRef = useRef(null); // Create a ref to the top element

    // State for the active tab, default to 'privacy'
    const [activeTab, setActiveTab] = useState("privacy");

    // Effect to check the query parameter and set the appropriate tab
    useEffect(() => {
        const query = new URLSearchParams(location.search);

        if (query.has("tnc")) {
            setActiveTab("tnc");
        } else if (query.has("refund")) {
            setActiveTab("refund");
        } else if (query.has("delivery")) {
            setActiveTab("delivery");
        } else {
            setActiveTab("privacy");  // Default to privacy if no valid query found
        }
    }, [location.search]);

    // Effect to scroll to the top of the page on component mount
    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    // Function to handle tab switching
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div id="PageContainer" ref={topRef}>
            <Header />
            <div id="BodyContent">
                <div className="tabs mobile-tabs">
                    <div>
                        <button onClick={() => handleTabClick("privacy")} className={`${activeTab === "privacy" ? "active-policy" : ""} first-policy`}>Privacy Policy</button>
                        <button onClick={() => handleTabClick("tnc")} className={activeTab === "tnc" ? "active-policy" : ""}>Terms and Conditions</button>
                    </div>
                    <div>
                        <button onClick={() => handleTabClick("refund")} className={activeTab === "refund" ? "active-policy" : ""}>Refund Policy</button>
                        <button onClick={() => handleTabClick("delivery")} className={`${activeTab === "delivery" ? "active-policy" : ""} last-policy`}>Delivery Policy</button>
                    </div>
                </div>
                <div className="tabs pc-tabs">
                    <button onClick={() => handleTabClick("privacy")} className={`${activeTab === "privacy" ? "active-policy" : ""} first-policy`}>Privacy Policy</button>
                    <button onClick={() => handleTabClick("tnc")} className={activeTab === "tnc" ? "active-policy" : ""}>Terms and Conditions</button>
                    <button onClick={() => handleTabClick("refund")} className={activeTab === "refund" ? "active-policy" : ""}>Refund Policy</button>
                    <button onClick={() => handleTabClick("delivery")} className={`${activeTab === "delivery" ? "active-policy" : ""} last-policy`}>Delivery Policy</button>
                </div>
                <div className="tab-content">
                    {activeTab === "privacy" && <div><PrivacyPolicy /></div>}
                    {activeTab === "tnc" && <div><TermsAndConditions /></div>}
                    {activeTab === "refund" && <div><RefundPolicy /></div>}
                    {activeTab === "delivery" && <div><DeliveryPolicy /></div>}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Policy;
