import React, { useState, useEffect } from 'react';
import Cookies from 'react-cookies';
import Header from "../components/header";
import Footer from "../components/footer";
import "../App.css";
import "../styles/card.css";
import "../styles/checkout.css";
import { API_ENDPOINT } from "../App";
import { payNow } from '../utils/paymentGateway';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import validator from 'validator'


const Checkout = () => {
    const [userName, setUserName] = useState('');
    const [address, setAddress] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [bill, setBill] = useState([0, 0]);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');

    function validateInput() {
        if (userName === '') {
            setSnackBarMessage("Please enter reciever's name")
            setShowSnackBar(true);
            return false;
        }
        if (mobile === '') {
            setSnackBarMessage("Please enter reciever's phone number")
            setShowSnackBar(true);
            return false;
        }
        if (!validator.isMobilePhone(mobile)) {
            setSnackBarMessage("Please enter a valid phone number")
            setShowSnackBar(true);
            return false;
        }
        if (address === '') {
            setSnackBarMessage("Please enter reciever's address")
            setShowSnackBar(true);
            return false;
        }
        if (email !== '' && !validator.isEmail(email)) {
            setSnackBarMessage("Please enter a valid email address")
            setShowSnackBar(true);
            return false;
        }
        return true;
    }

    const handleOrderPlacement = async () => {
        if (validateInput()) {
            const userData = {
                name: userName,
                address: address,
                phone_number: mobile,
                email: email
            };

            const orderData = {
                items: Cookies.load('cart') || []
            };

            const transactionData = {
                amount: bill[0] + bill[1] + 30,
                number: mobile,
                redirect_url: `${window.location.origin}/checkPaymentStatus`
            };

            try {
                await payNow(userData, orderData, transactionData);
            } catch (error) {
                console.error("Error placing order:", error);
                setSnackBarMessage("Hmm! Something isn't working right.\n Please contact us if error persists")
                setShowSnackBar(true);
            }
        }
    };

    const calculateBill = () => {
        let cart = Cookies.load('cart') || [];
        let initBill = 0;
        let gstBill = 0;

        cart.forEach(item => {
            fetch(`${API_ENDPOINT}/inv/${item.id}`)
                .then(response => response.json())
                .then(data => {
                    initBill += item.qty * data.price;
                    gstBill = Math.round(initBill * 18) / 100;
                    gstBill = Math.round(initBill * 0) / 100;
                    setBill([initBill, gstBill]);
                })
                .catch(error => console.error(error));
        });
    };

    useEffect(() => {
        calculateBill();
    }, []);

    return (
        <div id='PageContainer'>
            <Header />
            <>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={showSnackBar}
                    autoHideDuration={20000}
                    onClose={(e) => {
                        setShowSnackBar(false);
                    }}
                    key='snackBar'
                >
                    <Alert
                        onClose={(e) => {
                            setShowSnackBar(false);
                        }}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackBarMessage}
                    </Alert>
                </Snackbar>
            </>
            <div id="checkoutPage">
                <div id="checkoutForm">
                    <div id='billHeaderTop'>Billing Details</div>
                    <form>
                        <div className="form-group">
                            <label className='form-labels' htmlFor="name">Name <span className='required-text'>*</span></label>
                            <input
                                type="text"
                                id="username"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                autoComplete='name'
                                required

                            />
                        </div>
                        <div className="form-group">
                            <label className='form-labels' htmlFor="mobile">Mobile Number <span className='required-text'>*</span></label>
                            <input
                                type="text"
                                id="mobile"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                autoComplete='mobile'
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className='form-labels' htmlFor="address">Address <span className='required-text'>*</span></label>
                            <textarea
                                id="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                autoComplete='address-level4'
                                required
                            ></textarea>
                        </div>

                        <div className="form-group">
                            <label className='form-labels' htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete='email'
                            />
                        </div>
                        <div id="checkoutFormFooter">
                            <div id="billDetailsCheckout">
                                <div id="billHeader">Your Order</div>
                                <div id="billData">
                                    <div>
                                        <span>Subtotal:</span><span className='priceText'> ₹{bill[0]}</span>
                                    </div>
                                    <div>
                                        <span>GST (18%):</span><span className='priceText'> ₹{bill[1]}</span>
                                    </div>
                                    <div>
                                        <span>Shipping:</span><span className='priceText'> ₹30</span>
                                    </div>
                                </div>
                                <div id='totalBill'><span id="totalPay">Total:</span> ₹{Math.round((bill[0] + bill[1] + (bill[0] !== 0 ? 30 : 0)) * 100) / 100}</div>
                            </div>
                            <button type="button" id="place-order" className='pay-online' onClick={handleOrderPlacement}>Place Order</button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Checkout;
