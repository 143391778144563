import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Order from './pages/order';
import Home from './pages/home';
import ContactUs from './pages/contact';
import Cart from './pages/cart';
import NotFound from './pages/404';
import Checkout from './pages/Checkout';
import Policy from './pages/policies';
import CheckPaymentStatus from './pages/paymentStatus';
import { CookiesProvider } from 'react-cookie';
import {config} from './config';
import OrderSuccessPage from './pages/orderSuccess';


export const API_ENDPOINT = config.url.API_ENDPOINT;
export const IMG_ENDPOINT = config.url.IMG_ENDPOINT;
export const PAYMENT_ENDPOINT = config.url.PG_ENDPOINT;


// Main App component
const App = () => {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/order" element={<Order />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/order-now" element={<Order />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path='/policy' element={<Policy />} />
          <Route path='/checkPaymentStatus' element={<CheckPaymentStatus />} />
          <Route path='/orderPlaced' element={<OrderSuccessPage />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </CookiesProvider>
  );
};

export default App;
