import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../styles/card.css"; // Reuse the same CSS for consistent styling

const Loading = () => {
    return (
        <div className="card_body">
            <div className="card_img">
                <Skeleton height={200} />
            </div>
            <div className="card_info">
                <div className="item_name">
                    <Skeleton width={`80%`} />
                </div>
                <div className="item_price">
                    <Skeleton width={`60%`} />
                </div>
                <div className="item_info">
                    <Skeleton count={3} />
                </div>
                <button className="additemskeleton">
                    <Skeleton width={80} height={30} />
                </button>
            </div>
        </div>
    );
}

export default Loading;
