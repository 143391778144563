import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import EmptyCart from "../components/emptyCart";
import Cookies from 'react-cookies';
import ListComponent from "../components/cartComponents";
import Loading from "../components/loading";

import "../App.css";

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadCartItems = () => {
        let cart = Cookies.load('cart') || [];
        setCartItems(cart);
        setIsLoading(false);
    };

    useEffect(() => {
        loadCartItems(); // Initial load

        const interval = setInterval(() => {
            loadCartItems(); // Update cart items periodically
        }, 1000); // Check every second

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div id="PageContainer">
            <Header />
            <div id="BodyContent">
                {isLoading ? (
                    <Loading />
                ) : cartItems.length === 0 ? (
                    <EmptyCart />
                ) : (
                    <ListComponent items={cartItems} />
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Cart;
