import React, { useState, useEffect } from 'react';
import Cookies from 'react-cookies';
import "../styles/cart.css";

const CartNotification = () => {
    const [cartItems, setCartItems] = useState([]);
    const [visible, setVisible] = useState(false);

    const updateCart = () => {
        const cart = Cookies.load('cart') || [];
        setCartItems(cart);
        setVisible(cart.length > 0 && cart.some(item => item.qty > 0));
    };

    useEffect(() => {
        updateCart();

        // Reduce the interval to 500ms for more frequent updates
        const intervalId = setInterval(updateCart, 100); // Decrease this interval when Load increases on website

        return () => clearInterval(intervalId);
    }, []);

    const totalItems = cartItems.reduce((total, item) => total + item.qty, 0);

    if (!visible) return null; // Hide component if no items in cart

    return (
        <div className="cart-notification">
            <div id="notification-data">
                <span>{totalItems} item{totalItems > 1 ? 's' : ''} added</span>
                <button className='additem stylishHeading viewCart' onClick={() => window.location.href = '/cart'}>View Cart</button>
            </div>
        </div>
    );
};

export default CartNotification;
